import React, { useState, useEffect } from 'react';
import LoginScreen from './screens/login';
import OrderScreen from './screens/orderScreen';
import AuthService from "../src/services/authService";
import { Snackbar } from '@mui/material';
import { SESSION_KEY, MOBILE_BREAKPOINT } from '../src/utils/constants';

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [snackIsOpen, setSnackIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userIsConnected, setUserIsConnected] = useState(false);
  const [width, setWindowWidth] = useState(0);
  const authService = new AuthService();

  useEffect(()=> {
    setDimensions();

    window.addEventListener('resize', setDimensions);

    return () => window.removeEventListener('resize', setDimensions);
  },[])

  const setDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  }

  const handleLogin = async (userData) => {
    setIsLoading(true);
     authService.login(userData).then((response)=> {
      if(response?.access_token){
        setUserIsConnected(true);
        sessionStorage.setItem(SESSION_KEY, response.access_token);
      }
      setIsLoading(false);
     })
     .catch((error)=>{
      setErrorMessage(error);
      setSnackIsOpen(true)
     })
  };

  const handleCloseSnack = () => {
    setSnackIsOpen(false);
  }

  const isMobile = width < MOBILE_BREAKPOINT;

  return (
    <div>
      {userIsConnected ? (
        <OrderScreen isMobile={isMobile} />
        ) : (
          <LoginScreen isMobile={isMobile} isLoading={isLoading} onSubmit={handleLogin} />
      )}
      <Snackbar
        open={snackIsOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message={errorMessage}
      />
    </div>
  );
}

export default App;
